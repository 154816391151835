footer {
  margin-top: 60px;
  padding-top: 40px;
  padding-bottom: 15px;
min-height: 250px;
}

.nuovo-indirizzo .form-check-label, .seleziona .form-check-label {
  display: block;
}
.checkout-steps {
    margin-bottom: 40px;
  }
  .pb-20 {
    padding-bottom: 20px;
  }
  .pt-20 {
    padding-top: 20px;
  }
  .mb-20 {
    margin-bottom: 20px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .checkout-steps::after {
    display: block;
    clear: both;
    content: '';
  }
  
  .checkout-steps > a {
    display: block;
    position: relative;
    width: 25%;
    height: 55px;
    float: right;
    transition: color .3s;
    border-top: 1px solid #e1e7ec;
    border-bottom: 1px solid #e1e7ec;
    background-color: #ffffff;
    color: #606975;
    font-size: 14px;
    font-weight: 500;
    line-height: 53px;
    text-decoration: none;
    text-align: center;
  }
  
  .checkout-steps > a > .angle {
    display: block;
    position: absolute;
    top: 0;
    right: -13px;
    width: 27px;
    height: 53px;
    background-color: #ffffff;
  }
  
  .checkout-steps > a > .angle::before, .checkout-steps > a > .angle::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: solid transparent;
    content: '';
    pointer-events: none;
  }
  
  .checkout-steps > a > .angle::after {
    border-width: 26px;
    border-color: transparent;
    border-left-color: #ffffff;
  }
  
  .checkout-steps > a > .angle::before {
    margin-top: -1px;
    border-width: 27px;
    border-color: transparent;
    border-left-color: #d8e0e6;
  }
  
  .checkout-steps > a:hover {
    color: $cyan;
  }
  
  .checkout-steps > a.active {
    background-color: $bg-color;
    color: #ffffff;
    cursor: default;
    pointer-events: none;
  }
  
  .checkout-steps > a.active > .angle::after {
    border-left-color: $bg-color;
  }
  
  .checkout-steps > a.active + a > .angle {
    background-color: $bg-color;
  }
  
  .checkout-steps > a.completed > .step-indicator {
    display: inline-block;
    margin-top: -5px;
    margin-right: 7px;
    border-radius: 50%;
    color: $teal;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
  }
  
  .checkout-steps > a.completed:hover {
    color: #606975;
  }
  
  .checkout-steps > a:first-child {
    border-right: 1px solid $gray-300;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  
  .checkout-steps > a:last-child {
    border-left: 1px solid $gray-300;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  
.lock {
  padding-top: 5px;
  float: right;
}
.user {
  float: right;
  margin-right: 15px;

}
.list-group.col-12 {
  padding-left: 15px;
}


.visible-xs {
  display: none;

}

@media (min-width: 993px) {

 
  .hidden-lg {
      display: none;

  }

}
@media (max-width: 992px) {

 
  .hidden-md {
      display: none;

  }

}

@media (max-width: 768px) {

 
  .hidden-sm {
      display: none;

  }

}

  @media (max-width: 576px) {
    .checkout-steps > a {
      width: 25%;
      margin-bottom: 10px;
      float: right;
      border: 1px solid #e1e7ec;
      border-radius: 0;
    }
   
    .hidden-xs {
        display: none;

    }
    img.brand-logo {
        max-width: 130px;
    }
    .visible-xs {
      display: inline-block;
    
    }
  }